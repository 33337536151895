import React, { Component } from 'react';
import HeroAntiDoping from './HeroAntiDoping';
import AntiDopingFeatures from './AntiDopingFeatures';
import ContactUs from './ContactUs';
import './AntiDoping.css';

export class AntiDoping extends Component {

  render () {
    return (
        <>
            <HeroAntiDoping />
            <AntiDopingFeatures />
            <ContactUs />
        </>
    );
  }
}
