import React, { Component } from 'react';
import Modal from './Modal';
import './MobileApps.css';

export default class MobileApps extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalSlide: 0,
            modalVisible: false
        };
    }

    nextSlide() {
        this.setState(state => {
            return { modalSlide: state.modalSlide === 2 ? 0 : state.modalSlide + 1 }
        });
    }

    prevSlide() {
        this.setState(state => {
            return { modalSlide: state.modalSlide === 0 ? 2 : state.modalSlide - 1 }
        });
    }

    render() {
        return (
            <>
                <div id="mobileapps">
                    <h1 className="center-title">Mobile Apps</h1>
                    <div className="wrap">
                        <img src="./images/mobile-platforms.png" width="200" className="platforms" />
                        <p>Our mobile apps team delivers robust, secure apps optimized for phones and tablets on both iOS and
                        Android. These apps utilize features such as full offline capabilities, biometric identification and
                multi-language support.</p>
                        <img src="./images/mobile-apps.png" width="200" className="mobileapps-img" />
                        <span className="view-our-work" onClick={() => { this.setState({ modalVisible: true }) }}><span className="linktext">View Our Work</span><span
                            className="arrow fa fa-angle-right"></span></span>
                    </div>
                    <br className="clearfix" />
                </div>
                <Modal
                    prevSlide={() => this.prevSlide()}
                    nextSlide={() => this.nextSlide()}
                    onHide={() => { this.setState({ modalVisible: false }) }}
                    isVisible={this.state.modalVisible}
                >
                    <div id="mobile-0" style={{ display: this.state.modalSlide === 0 ? 'block' : 'none' }}>
                        <div className="caption">
                            <h3>AD5</h3>
                            <p>
                                AD5 is a tablet-based app that allows DCOs to review mission assignments and complete the entire sample collection process. The app supports IC and OOC testing and urine, blood and dried blood spot samples. The app's robust, secure API enables it to be integreated seamlessly with an ADO's anti-doping management system.
                            </p>
                        </div>
                        <img src="./images/mobile-ad5.png" width="361" height="300" />
                    </div>
                    <div id="mobile-1" style={{ display: this.state.modalSlide === 1 ? 'block' : 'none' }}>
                        <div className="caption">
                            <h3>Athlete Express</h3>
                            <p>
                                The Athlete Express iOS and Android apps are used by thousands of U.S. athletes to manage their whereabouts and other critical information for the U.S. Anti-Doping Agency.
                            </p>
                        </div>
                        <img src="./images/mobile-aea.png" width="361" height="300" />
                    </div>
                    <div id="mobile-2" style={{ display: this.state.modalSlide === 2 ? 'block' : 'none' }}>
                        <div className="caption">
                            <h3>DFSNZ Education App</h3>
                            <p>
                                This iOS app enables DFSNZ educators to organize their schedule of workshops and record attendance. The attendee information is synchronized with Simon, DFSNZ's back-end system, providing the organization will very detailed reporting on workshop attendees.
                            </p>
                        </div>
                        <img src="./images/mobile-dfsnz.png" width="361" height="300" />
                    </div>
                </Modal>
            </>
        );
    }
}
