import React, { Component } from 'react';
import Modal from './Modal';
import './WebApps.css';

export default class WebApps extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalSlide: 0,
            modalVisible: false
        };
    }

    nextSlide() {
        this.setState(state => {
            return { modalSlide: state.modalSlide === 2 ? 0 : state.modalSlide + 1 }
        });
    }

    prevSlide() {
        this.setState(state => {
            return { modalSlide: state.modalSlide === 0 ? 2 : state.modalSlide - 1 }
        });
    }

    render() {
        return (
            <>
                <div id="webapps">
                    <a name="work" style={{ position: 'relative', top: '-125px' }}></a>
                    <a name="work-mobile"></a>
                    <h1 className="center-title">Web Applications</h1>
                    <div className="wrap">
                        <img src="./images/web-apps.png" width="350" className="webapps-img" />
                        <div className="info">
                            <p>We build secure, enterprise-class web applications optimized for all devices and web browsers. We
                            design and develop all required middle- and back-end components such as databases and APIs. Our
                            software enables many types of teams to collaborate securely across multiple time zones and
                    languages.</p>
                            <div className="technologies">
                                <img src="./images/dotnet.png" width="125" height="125" />
                                <img src="./images/react.png" width="125" height="125" />
                                <img src="./images/azure.png" width="125" height="125" />
                                <img src="./images/aws.png" width="125" height="125" />
                                <img src="./images/firebase.png" width="125" height="125" />
                            </div>
                            <span className="view-our-work" onClick={() => { this.setState({ modalVisible: true }) }}><span className="linktext">View Our Work</span><span
                                className="arrow fa fa-angle-right"></span></span>
                        </div>
                    </div>
                    <br className="clearfix" />
                </div>
                <Modal
                    prevSlide={() => this.prevSlide()}
                    nextSlide={() => this.nextSlide()}
                    onHide={() => { this.setState({modalVisible: false}) }}
                    isVisible={this.state.modalVisible}
                >
                    <div id="webapp-0" className="modal-slide" style={{ display: this.state.modalSlide === 0 ? 'block' : 'none' }}>
                        <div className="caption">
                            <h3>Athlete Express</h3>
                            <p>
                                Athlete Express is a web portal used by the U.S. Anti-Doping Agency, Antidoping Switzerland and Drug Free Sport New Zealand. Thousands of athletes use the portal to manage their whereabouts information and receive important alerts and other information.
                            </p>
                        </div>
                        <img src="./images/webapp-aea.png" width="361" height="300" />
                    </div>
                    <div id="webapp-1" className="modal-slide" style={{ display: this.state.modalSlide === 1 ? 'block' : 'none' }}>
                        <div className="caption">
                            <h3>Beeline Legal</h3>
                            <p>
                                Beeline Legal allows users to easily and affordably submit trademark applications to the U.S. Patent and Trademark Office (USPTO). The website walks users step-by-step through the application process and searches the entire PTO database to determine trademark availability.
                            </p>
                        </div>
                        <img src="./images/webapp-beeline.png" width="361" height="300" />
                    </div>
                    <div id="webapp-2" className="modal-slide" style={{ display: this.state.modalSlide === 2 ? 'block' : 'none' }}>
                        <div className="caption">
                            <h3>Global DRO</h3>
                            <p>
                                Global DRO is a powerful network of national anti-doping organizations that empowers athletes to easily determine if a licensed medication is prohibited or not prohibited in accordance with the World Anti-Doping Agency Prohibited List. Available in multiple languages, Global DRO supports well over 100,000 searches every month and is an invaluable tool in the mission to protect clean athletes.
                            </p>
                        </div>
                        <img src="./images/webapp-dro.png" width="361" height="300" />
                    </div>
                </Modal>
            </>
        );
    }
}
