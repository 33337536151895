import React, { Component } from 'react';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.state = {
            showMobileMenu: false,
            scrollPosition: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', () => this.pageDidScroll());
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', () => this.pageDidScroll());
    }

    pageDidScroll() {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        this.setState({
            scrollPosition: winScroll
        });
    }

    scrollIsBetween(upper, lower) {
        return window.location.pathname === '/' && this.state.scrollPosition >= upper && this.state.scrollPosition <= lower;
    }

    render() {
        return (
            <div>
                <div id="nav">
                    <a href="#"><img src="./images/vb-logo.png" width="162" height="75" alt="Visionbox" /></a>
                    <ul>
                        <li><a href="#contact" className="yellow-box">Contact Us</a></li>
                        <li><a href="./antidoping" className={window.location.pathname === '/antidoping' ? 'active' : ''}>Anti-Doping</a></li>
                        <li><a href="#process">Process</a></li>
                        <li><a href="#work">Work</a></li>
                        <li><a href="#about">About</a></li>
                    </ul>
                </div>
                {this.state.scrollPosition >= 100 &&
                    <div id="nav-floating">
                        <a href="#"><img src="./images/vb-square.png" width="75" height="75" alt="Visionbox" /></a>
                        <ul>
                            <li><a href="#contact" className="yellow-box">Contact Us</a></li>
                            <li><a href="./antidoping" className={window.location.pathname === '/antidoping' ? 'active' : ''}>Anti-Doping</a></li>
                            <li><a id="nav-process" href="#process" className={this.scrollIsBetween(2264, 3062) ? 'active' : ''}>Process</a></li>
                            <li><a id="nav-work" href="#work" className={this.scrollIsBetween(1320, 2264) ? 'active' : ''}>Work</a></li>
                            <li><a id="nav-about" href="#about" className={this.scrollIsBetween(610, 1320) ? 'active' : ''}>About</a></li>
                        </ul>
                    </div>
                }
                <div id="nav-mobile">
                    <a href="#"><img src="./images/vb-logo.png" width="162" height="75" alt="Visionbox" /></a>
                    <span className="fa fa-bars" onClick={() => { this.setState({ showMobileMenu: true }) }}></span>
                </div>
                {this.state.showMobileMenu &&
                    <div id="nav-mobile-menu" onClick={() => { this.setState({ showMobileMenu: false }) }}>
                        <ul>
                            <li><a href="#about-mobile">About</a></li>
                            <li><a href="#work-mobile">Work</a></li>
                            <li><a href="#process-mobile">Process</a></li>
                            <li><a href="./antidoping">Anti-Doping</a></li>
                            <li><a href="#contact-mobile">Contact Us</a></li>
                        </ul>
                    </div>
                }

                {this.props.children}


                <div id="footer">
                    <p className="left-foot">
                        7222 Commerce Center Drive<br />
                            Suite 228<br />
                            Colorado Springs, CO 80919
                    </p>
                    <p className="right-foot">
                        719.484.8886<br />
                        <a href="mailto:info@visionbox.com">info@visionbox.com</a><br />
                        <a href="#" className="fa fa-angle-up"></a>
                    </p>
                    <br className="clearfix" />
                </div>
            </div>
        );
    }
}