import React, { Component } from 'react';
import './HeroAntiDoping.css';

export default class HeroAntiDoping extends Component {

  render () {
    return (
        <div id="hero-antidoping">
            <h1 className="hero-title">Enterprise Anti-Doping <br />Software Used Worldwide</h1>
            <p>Over 250,000 tests completed throughtout the world</p>
        </div>
    );
  }
}
