import React, { Component } from 'react';
import HeroHome from './HeroHome';
import WhoWeAre from './WhoWeAre';
import WebApps from './WebApps';
import MobileApps from './MobileApps';
import HowWeDoIt from './HowWeDoIt';
import ContactUs from './ContactUs';

import './Home.css';

export class Home extends Component {
  static displayName = Home.name;

  render () {
      return (
        <>
          <HeroHome />
          <WhoWeAre />
          <WebApps />
          <MobileApps />
          <HowWeDoIt />
          <ContactUs />
        </>
    );
  }
}
