import React, { Component } from 'react';
import './AntiDopingFeatures.css';

export default class AntiDopingFeatures extends Component {

    render() {
        return (
            <div id="main">
                <h1 className="center-title">Used By</h1>
                <div id="clients">
                    <img src="./images/asada.png" width="85" height="90" title="Australian Sports Anti-Doping Authority"  alt="asada" />
                    <img src="./images/cces.png" width="200" height="90" title="Canadian Center for Ethics in Sport"  alt="cces" />
                    <img src="./images/dfsnz.png" width="90" height="90" title="Drugfree Sport New Zealand"  alt="dfsnz" />
                    <img src="./images/ita-black.png" height="80" title="International Testing Agency"  alt="ita" /><br />
                    <img src="./images/jada.png" width="90" height="90" title="Japan Anti-Doping Agency"  alt="jada" />
                    <img src="./images/rf.png" height="80" title="Anti-Doping Sweden"  alt="rf" />
                    <img src="./images/si.png" width="90" height="90" title="Sport Ireland Anti-Doping"  alt="si" />
                    <img src="./images/ssi.png" height="80" title="Swiss Sport Integrity" alt="ssi" />
                    <img src="./images/ukad.png" width="80" height="80" title="UK Anti-Doping"  alt="ukad" />
                    <img src="./images/usadaBlack.png" width="90" height="90" title="US Anti-Doping Agency"  alt="usada" />
                </div>
                <hr />
                <h1 className="center-title">Anti-Doping Software</h1>
                <div className="wrap">
                    <div className="left-col">
                        <div className="feature-tile" style={{ height: "180px" }}>
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path d="M16,11.78L20.24,4.45L21.97,5.45L16.74,14.5L10.23,10.75L5.46,19H22V21H2V3H4V17.54L9.5,8L16,11.78Z" />
                            </svg>
                            <p>
                                <strong>TDP</strong><br />
                                Manage and track testing goals, both for the organization and individual athletes. Track
                                compliance
                                with programs such as the WADA Technical Document for Sport Specific Analysis (TDSSA).
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="feature-tile" style={{ height: "180px" }}>
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path d="M9,5V6H15V5H9M22,18C22,18.53 21.79,19 21.4,19.41C21,19.81 20.55,20 20,20H4C3.45,20 3,19.81 2.6,19.41C2.21,19 2,18.53 2,18V14H7V15H9V14H15V15H17V14H22V18M4.5,7.22C4.84,6.41 5.45,6 6.33,6H7V5C7,4.45 7.18,4 7.57,3.59C7.96,3.2 8.44,3 9,3H15C15.56,3 16.04,3.2 16.43,3.59C16.82,4 17,4.45 17,5V6H17.67C18.55,6 19.16,6.41 19.5,7.22L21.58,12H17V11H15V12H9V11H7V12H2.42L4.5,7.22Z" />
                            </svg>
                            <p>
                                <strong>Missions</strong><br />
                                Create In-Competition and Out-of-Competition missions and manage all aspects including athlete
                                selection, assigning sample collection personnel and tracking mission status.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="feature-tile">
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
                            </svg>
                            <p>
                                <strong>Whereabouts Failures</strong><br />
                                Create and track whereabouts failures, from creation through final decision. Generate athlete
                                notification letters, either for individual failures or in bulk.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="feature-tile">
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M4.22,11.29L11.29,4.22C13.64,1.88 17.43,1.88 19.78,4.22C22.12,6.56 22.12,10.36 19.78,12.71L12.71,19.78C10.36,22.12 6.56,22.12 4.22,19.78C1.88,17.43 1.88,13.64 4.22,11.29M5.64,12.71C4.59,13.75 4.24,15.24 4.6,16.57L10.59,10.59L14.83,14.83L18.36,11.29C19.93,9.73 19.93,7.2 18.36,5.64C16.8,4.07 14.27,4.07 12.71,5.64L5.64,12.71Z" />
                            </svg>
                            <p>
                                <strong>Medication Checking</strong><br />
                                The Global Drug Reference Online (Global DRO) provides information about the prohibited status of medications based on the current World Anti-Doping Agency Prohibited List. Global DRO covers mediations from around the world, specific brands for 7 different countries and has serviced millions of requests from all over the world.
                    </p>
                            <br className="clearfix" />
                        </div>
                    </div>
                    <div className="right-col">
                        <div className="feature-tile" style={{ height: "180px" }}>
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path d="M19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M7 7H17V5H19V19H5V5H7V7M12 17V15H17V17H12M12 11V9H17V11H12M8 12V9H7V8H9V12H8M9.25 14C9.66 14 10 14.34 10 14.75C10 14.95 9.92 15.14 9.79 15.27L8.12 17H10V18H7V17.08L9 15H7V14H9.25" />
                            </svg>
                            <p>
                                <strong>Results Management</strong><br />
                                Import lab results using standard WADA .csv and .xml files. Case management functions for
                                analytical and non-analytical Anti-Doping Rules Violations. Extended tracking for steroidal and
                                haematological Athlete Biological Passport values.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="feature-tile" style={{ height: "180px" }}>
                            <svg height="60px" width="90px" viewBox="0 0 24 24" style={{ marginTop: "-9px" }}>
                                <path
                                    d="m12,5.5a3.5,3.5 0 0 1 3.5,3.5a3.5,3.5 0 0 1 -3.5,3.5a3.5,3.5 0 0 1 -3.5,-3.5a3.5,3.5 0 0 1 3.5,-3.5m-7,2.5c0.56,0 1.08,0.15 1.53,0.42c-0.15,1.43 0.27,2.85 1.13,3.96c-0.5,0.96 -1.5,1.62 -2.66,1.62a3,3 0 0 1 -3,-3a3,3 0 0 1 3,-3m14,0a3,3 0 0 1 3,3a3,3 0 0 1 -3,3c-1.16,0 -2.16,-0.66 -2.66,-1.62c0.86,-1.11 1.28,-2.53 1.13,-3.96c0.45,-0.27 0.97,-0.42 1.53,-0.42m-13.5,10.25c0,-2.07 2.91,-3.75 6.5,-3.75c3.59,0 6.5,1.68 6.5,3.75l0,1.75l-13,0l0,-1.75m-5.5,1.75l0,-1.5c0,-1.39 1.89,-2.56 4.45,-2.9c-0.59,0.68 -0.95,1.62 -0.95,2.65l0,1.75l-3.5,0m24,0l-3.5,0l0,-1.75c0,-1.03 -0.36,-1.97 -0.95,-2.65c2.56,0.34 4.45,1.51 4.45,2.9l0,1.5z" />
                            </svg>
                            <p>
                                <strong>Testing Pools</strong><br />
                                Complete management of testing pools. Adding/removing athletes individual or in bulk. Creation
                                and distribution of pool inclusion and exclusion communication to athletes and relevant
                                authorities such as National and International Federations.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="feature-tile">
                            <svg height="60px" width="90px" viewBox="0 0 24 24" style={{ marginTop: "-7px" }}>
                                <path fill="currentColor" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
                            </svg>
                            <p>
                                <strong>Third Party Access</strong><br />
                                Secure access for third-parties such as international federations and sample collection
                                agencies. Share testing statistics, details of mission assignments and more.
                    </p>
                            <br className="clearfix" />
                        </div>
                    </div>
                </div>
                <br className="clearfix" />
                <br /><br />
                <hr />
                <h1 className="center-title">Mobile Mission Management</h1>
                <div className="wrap">
                    <div className="left-col">
                        <div className="feature-tile">
                            <svg height="60px" width="90px" viewBox="0 0 24 24" style={{ marginTop: "-8px" }}>
                                <path fill="currentColor" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
                            </svg>
                            <p>
                                <strong>Assignments</strong><br />
                                View details of accepted In-Competition and Out-of-Competition assignments. View documents
                                relevant to assignment such as Letter of Authority and Mission Order details.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="feature-tile" style={{ height: "180px" }}>
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M17,7H22V17H17V19A1,1 0 0,0 18,20H20V22H17.5C16.95,22 16,21.55 16,21C16,21.55 15.05,22 14.5,22H12V20H14A1,1 0 0,0 15,19V5A1,1 0 0,0 14,4H12V2H14.5C15.05,2 16,2.45 16,3C16,2.45 16.95,2 17.5,2H20V4H18A1,1 0 0,0 17,5V7M2,7H13V9H4V15H13V17H2V7M20,15V9H17V15H20Z" />
                            </svg>
                            <p>
                                <strong>Doping Control Forms</strong><br />
                                Collect blood, urine and dried blood spot samples. Gather additional information such as
                                medication/supplement declarations, athlete contacts such as doctor, coach and other
                                representatives, and record responses to Athlete Biological Passport questionnaire.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="feature-tile" style={{ height: "200px" }}>
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z" />
                            </svg>
                            <p>
                                <strong>Unsuccessful Attempts</strong><br />
                                Complete Unsuccessful Attempts including all locations visited during attempt. Use geolocation
                                and athlete whereabouts integration feature to pre-fill location details.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="feature-tile">
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
                            </svg>
                            <p>
                                <strong>Failure to Comply</strong><br />
                                Document attempt details such as witnesses and athlete contact details.
                    </p>
                            <br className="clearfix" />
                        </div>
                    </div>
                    <div className="right-col">
                        <div className="feature-tile">
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5M19.5,9.5L21.46,12H17V9.5M6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5M20,8H17V4H3C1.89,4 1,4.89 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8Z" />
                            </svg>
                            <p>
                                <strong>Shipping / Chain of Custody</strong><br />
                                Record all shipping details including destination lab, required analysis and temperature logger
                                details.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="feature-tile" style={{ height: "180px" }}>
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M4,1C2.89,1 2,1.89 2,3V7C2,8.11 2.89,9 4,9H1V11H13V9H10C11.11,9 12,8.11 12,7V3C12,1.89 11.11,1 10,1H4M4,3H10V7H4V3M14,13C12.89,13 12,13.89 12,15V19C12,20.11 12.89,21 14,21H11V23H23V21H20C21.11,21 22,20.11 22,19V15C22,13.89 21.11,13 20,13H14M3.88,13.46L2.46,14.88L4.59,17L2.46,19.12L3.88,20.54L6,18.41L8.12,20.54L9.54,19.12L7.41,17L9.54,14.88L8.12,13.46L6,15.59L3.88,13.46M14,15H20V19H14V15Z" />
                            </svg>
                            <p>
                                <strong>Offline Support</strong><br />
                                Complete support for offline operation. Once mission data is synced from server, tablet can
                                perform all core functions while offline and changes can be easily synced once an internet
                                connection is established.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="feature-tile" style={{ height: "200px" }}>
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M14.88,16.29L13,18.17V14.41M13,5.83L14.88,7.71L13,9.58M17.71,7.71L12,2H11V9.58L6.41,5L5,6.41L10.59,12L5,17.58L6.41,19L11,14.41V22H12L17.71,16.29L13.41,12L17.71,7.71Z" />
                            </svg>
                            <p>
                                <strong>Device Sync</strong><br />
                                Device Sync enables tablets that are offline to share and synchronize testing information. For
                                example, during In-Competition testing, one DCO can start a DCF and transfer the DCF to a
                                different DCO for completion. Useful in scenarios such as an athlete who provides a partial
                                sample and returns later to complete the process.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="feature-tile">
                            <svg height="60px" width="90px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M15.9,18.45C17.25,18.45 18.35,17.35 18.35,16C18.35,14.65 17.25,13.55 15.9,13.55C14.54,13.55 13.45,14.65 13.45,16C13.45,17.35 14.54,18.45 15.9,18.45M21.1,16.68L22.58,17.84C22.71,17.95 22.75,18.13 22.66,18.29L21.26,20.71C21.17,20.86 21,20.92 20.83,20.86L19.09,20.16C18.73,20.44 18.33,20.67 17.91,20.85L17.64,22.7C17.62,22.87 17.47,23 17.3,23H14.5C14.32,23 14.18,22.87 14.15,22.7L13.89,20.85C13.46,20.67 13.07,20.44 12.71,20.16L10.96,20.86C10.81,20.92 10.62,20.86 10.54,20.71L9.14,18.29C9.05,18.13 9.09,17.95 9.22,17.84L10.7,16.68L10.65,16L10.7,15.31L9.22,14.16C9.09,14.05 9.05,13.86 9.14,13.71L10.54,11.29C10.62,11.13 10.81,11.07 10.96,11.13L12.71,11.84C13.07,11.56 13.46,11.32 13.89,11.15L14.15,9.29C14.18,9.13 14.32,9 14.5,9H17.3C17.47,9 17.62,9.13 17.64,9.29L17.91,11.15C18.33,11.32 18.73,11.56 19.09,11.84L20.83,11.13C21,11.07 21.17,11.13 21.26,11.29L22.66,13.71C22.75,13.86 22.71,14.05 22.58,14.16L21.1,15.31L21.15,16L21.1,16.68M6.69,8.07C7.56,8.07 8.26,7.37 8.26,6.5C8.26,5.63 7.56,4.92 6.69,4.92A1.58,1.58 0 0,0 5.11,6.5C5.11,7.37 5.82,8.07 6.69,8.07M10.03,6.94L11,7.68C11.07,7.75 11.09,7.87 11.03,7.97L10.13,9.53C10.08,9.63 9.96,9.67 9.86,9.63L8.74,9.18L8,9.62L7.81,10.81C7.79,10.92 7.7,11 7.59,11H5.79C5.67,11 5.58,10.92 5.56,10.81L5.4,9.62L4.64,9.18L3.5,9.63C3.41,9.67 3.3,9.63 3.24,9.53L2.34,7.97C2.28,7.87 2.31,7.75 2.39,7.68L3.34,6.94L3.31,6.5L3.34,6.06L2.39,5.32C2.31,5.25 2.28,5.13 2.34,5.03L3.24,3.47C3.3,3.37 3.41,3.33 3.5,3.37L4.63,3.82L5.4,3.38L5.56,2.19C5.58,2.08 5.67,2 5.79,2H7.59C7.7,2 7.79,2.08 7.81,2.19L8,3.38L8.74,3.82L9.86,3.37C9.96,3.33 10.08,3.37 10.13,3.47L11.03,5.03C11.09,5.13 11.07,5.25 11,5.32L10.03,6.06L10.06,6.5L10.03,6.94Z" />
                            </svg>
                            <p>
                                <strong>Admin Portal</strong><br />
                                Secure admin portal that enables user management and processing of all incoming data. For
                                example, completed tests are validated and any issues flagged before data is uploaded to
                                back-end system.
                    </p>
                            <br className="clearfix" />
                        </div>
                    </div>
                </div>
                <br className="clearfix" />
            </div>
        );
    }
}
