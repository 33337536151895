import React, { Component } from 'react';
import './Modal.css';

export default class Modal extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    const body = document.getElementById("body");

    if (prevProps.isVisible === false && this.props.isVisible === true) {
      body.classList.add("modal-open");
    } else if (prevProps.isVisible === true && this.props.isVisible === false) {
      body.classList.remove("modal-open");
    }
  }

  render() {
    return (
      this.props.isVisible &&
      <div className="c-modal">
        <div className="modal" style={{display: 'block'}}>
          <i className="fa fa-angle-left modal-prev" onClick={() => { this.props.prevSlide() }}></i>
          <i className="fa fa-angle-right modal-next" onClick={() => { this.props.nextSlide() }}></i>
          <i className="fa fa-times-circle-o modal-close" onClick={() => { this.props.onHide() }}></i>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
