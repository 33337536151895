import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';
import './ContactUs.css';

export default class WhoWeAre extends Component {

    constructor(props) {
        super(props);
        this.state = {
            company: '',
            name: '',
            email: '',
            phone: '',
            notes: '',
            g_recaptcha_response: '',
            success: undefined
        };
    }

    submitForm() {
        fetch("./tellus/send", {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(this.state)
        })
            .then(response => {
                if (response.ok === true) {
                    this.setState({ success: true });
                } else {
                    this.setState({ success: false });
                }
            });
    }

    render() {
        return (
            <div id="contactus">
                <a name="contact" style={{ position: 'relative', top: '-105px' }}></a>
                <a name="contact-mobile"></a>
                <h1 className="center-title">How Can We Help?</h1>
                <div className="wrap">
                    {this.state.success === true &&
                        <div className="success-msg">
                            Your message has been received! Thank you!
                        </div>
                    }
                    {!this.state.success &&
                        <>
                            {this.state.success === false &&
                                <div className="success-msg">
                                    Please complete the entire form
                                </div>
                            }
                            <div className="left-form">
                                <input value={this.state.company} onChange={val => { this.setState({ company: val.target.value }) }} type="text" placeholder="Company" />
                                <input value={this.state.name} onChange={val => { this.setState({ name: val.target.value }) }} type="text" placeholder="Name" />
                                <input value={this.state.email} onChange={val => { this.setState({ email: val.target.value }) }} type="text" placeholder="Email" />
                                <input value={this.state.phone} onChange={val => { this.setState({ phone: val.target.value }) }} type="text" placeholder="Phone" />
                            </div>
                            <div className="right-form">
                                <textarea onChange={val => { this.setState({ notes: val.target.value }) }} placeholder="Tell Us What You Need" value={this.state.notes}/>
                            </div>
                            <Recaptcha
                                sitekey="6Le3IrkUAAAAAC5pqknpUF-pQHVFWbyHDUVpO16G"
                                render="explicit"
                                verifyCallback={(key) => { this.setState({ g_recaptcha_response: key }) }}
                                onloadCallback={() => { }}
                            />
                            <button type="button" onClick={() => { this.submitForm(); }}>Submit</button>
                        </>
                    }
                </div>
            </div>
        );
    }
}

