import React, { Component } from 'react';
import './WhoWeAre.css';

export default class WhoWeAre extends Component {

    render() {
        return (
            <div id="whoweare">
                <a name="about" style={{ position: 'relative', top: '-105px' }}></a>
                <a name="about-mobile"></a>
                <div id="clients">
                    <img src="./images/ita-black.png" height="80" alt="ITA" />
                    <img src="./images/cces.png" width="200" height="70" alt="CCES" />
                    <img src="./images/ssi.png" height="70" alt="SSI" />
                    <img src="./images/usadaBlack.png" height="80" alt="USADA" />
                    <img src="./images/beeline.png" height="80" alt="Beeline" />
                </div>
                <div id="clients-caption">We have clients in 23 countries across the globe</div>
                <hr />
                <h1 className="center-title">Who We Are</h1>
                <p id="para1">Visionbox offers technical consultation and software development capabilities, along with graphic
                    design and other creative services. Our primary focus is helping our clients understand the rapidly changing
                    technology landscape and using leading-edge technologies in innovative ways to create rock-solid,
                    cutting-edge software solutions.</p>
                <div id="para2">
                    <p>We have been fortunate to develop long-lasting and effective partnerships with many
                        outstanding clients. In fact, our two biggest clients today are the first two clients we started with in
                        2001. Our clients are both private and public companies and represent diverse industries.</p>
                    <img src="./images/established.png" width="250" height="193" />
                </div>
                <br className="clearfix" />
            </div>
        );
    }
}
