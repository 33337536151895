import React, { Component } from 'react';
import './HowWeDoIt.css';

export default class HowWeDoIt extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stepId: 0
        };
    }

    nextSlide() {
        this.setState(state => {
            return { stepId: state.stepId === 4 ? 0 : state.stepId + 1 }
        });
    }

    prevSlide() {
        this.setState(state => {
            return { stepId: state.stepId === 0 ? 4 : state.stepId - 1 }
        });
    }

    gotoSlide(slideNum) {
        this.setState({ stepId: slideNum });
    }

    render() {
        return (
            <div id="howwedoit">
                <a name="process" style={{ position: 'relative', top: '-125px' }}></a>
                <a name="process-mobile"></a>
                <h1 className="center-title">How We Do It</h1>
                <div className="wrap">
                    <p>Our proprietary Strategic Process ensures you get a no-nonsense solution to meet your real business need.
                    We guide each project through our proven 5-step proccess. And because every project is unique, our
                    Strategic Process is flexible and scalable to accomodate your project -- from discovery to delivery.</p>
                    <div className="tool-images">
                        <img className="preload" src={`./images/tile-0-default@3x.png`} width="150" />
                        <img className="tool-img" onClick={() => { this.gotoSlide(0) }} src={`./images/tile-0-${this.state.stepId === 0 ? 'selected' : 'default'}@3x.png`} width="150" />
                        <img className="tool-img" onClick={() => { this.gotoSlide(1) }} src={`./images/tile-1-${this.state.stepId === 1 ? 'selected' : 'default'}@3x.png`} width="150" />
                        <img className="tool-img" onClick={() => { this.gotoSlide(2) }} src={`./images/tile-2-${this.state.stepId === 2 ? 'selected' : 'default'}@3x.png`} width="150" />
                        <img className="tool-img" onClick={() => { this.gotoSlide(3) }} src={`./images/tile-3-${this.state.stepId === 3 ? 'selected' : 'default'}@3x.png`} width="150" />
                        <img className="tool-img" onClick={() => { this.gotoSlide(4) }} src={`./images/tile-4-${this.state.stepId === 4 ? 'selected' : 'default'}@3x.png`} width="150" />
                    </div>
                    <div className="tool-info">
                        <i id="tool-prev" className="fa fa-angle-left" onClick={() => this.prevSlide()}></i>
                        <div className="tool-txt" style={{ display: this.state.stepId === 0 ? 'block' : 'none' }}>
                            <h3>Discovery</h3>
                            <p>
                                To ensure success we'll launch your project with an in-depth Discovery Workshop,
                                which reveals requirements from all perspectives. We'll meet with you and your team to brainstorm
                                and build foundational assumptions, directions, and realistic goals for the project. In addition,
                                we'll establish expectations, discuss project details, such as roles and responsibilities, and
                                answer any questions you may have about our production process.
                    </p>
                        </div>
                        <div className="tool-txt" style={{ display: this.state.stepId === 1 ? 'block' : 'none' }}>
                            <h3>Design</h3>
                            <p>
                                Here, we conceptualize the users' experience at the "what, why,
                                and how" levels. For example: What can users do? Why will they want to do it? How will they do it?
                    </p>
                        </div>
                        <div className="tool-txt" style={{ display: this.state.stepId === 2 ? 'block' : 'none' }}>
                            <h3>Develop</h3>
                            <p>
                                Here, we build your solution! Our technical team tests
                                extensively throughout the Develop Phase. This provides a solid foundation of quality assurance as
                                we weave together project components.
                    </p>
                        </div>
                        <div className="tool-txt" style={{ display: this.state.stepId === 3 ? 'block' : 'none' }}>
                            <h3>Launch</h3>
                            <p>
                                At this juncture, you review your project, ensure it meets the
                                agreed-upon goals, and give final approval. We then move your project to its final platform, conduct
                                final testing, and ensure the live solution is stable and successful.
                    </p>
                        </div>
                        <div className="tool-txt" style={{ display: this.state.stepId === 4 ? 'block' : 'none' }}>
                            <h3>Evolve</h3>
                            <p>
                                Don't worry, we don't "launch and run." We consider our clients
                                to be our partners. One of our key philosophies is building long-term relationships with our clients
                                to help them meet evolving business needs.
                    </p>
                        </div>
                        <i id="tool-next" className="fa fa-angle-right" onClick={() => this.nextSlide()}></i>
                    </div>
                    <div className="tool-mobile">
                        <div className="tool-txt">
                            <img src="./images/tile-0-selected@3x.png" width="100" />
                            <p>
                                <strong>Discovery</strong><br />
                            To ensure success we'll launch your project with an in-depth Discovery Workshop,
                            which reveals requirements from all perspectives. We'll meet with you and your team to brainstorm
                            and build foundational assumptions, directions, and realistic goals for the project. In addition,
                            we'll establish expectations, discuss project details, such as roles and responsibilities, and
                            answer any questions you may have about our production process.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="tool-txt">
                            <img src="./images/tile-1-selected@3x.png" width="100" />
                            <p>
                                <strong>Design</strong><br />
                            Here, we conceptualize the users' experience at the "what, why,
                            and how" levels. For example: What can users do? Why will they want to do it? How will they do it?
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="tool-txt">
                            <img src="./images/tile-2-selected@3x.png" width="100" />
                            <p>
                                <strong>Develop</strong><br />
                            Here, we build your solution! Our technical team tests
                            extensively throughout the Develop Phase. This provides a solid foundation of quality assurance as
                            we weave together project components.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="tool-txt">
                            <img src="./images/tile-3-selected@3x.png" width="100" />
                            <p>
                                <strong>Launch</strong><br />
                            At this juncture, you review your project, ensure it meets the
                            agreed-upon goals, and give final approval. We then move your project to its final platform, conduct
                            final testing, and ensure the live solution is stable and successful.
                    </p>
                            <br className="clearfix" />
                        </div>
                        <div className="tool-txt">
                            <img src="./images/tile-4-selected@3x.png" width="100" />
                            <p>
                                <strong>Evolve</strong><br />
                            Don't worry, we don't "launch and run." We consider our clients
                            to be our partners. One of our key philosophies is building long-term relationships with our clients
                            to help them meet evolving business needs.
                    </p>
                            <br className="clearfix" />
                        </div>
                    </div>
                </div>
                <br className="clearfix" />
            </div>
        );
    }
}
